import React from 'react'
const PlayIcon = ({ ...rest }) => {
    return (
        <>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                viewBox='0 0 204.687 204.687'
                {...rest}
            >
                <defs>
                    <filter id='Play_icon' x='0' y='0' filterUnits='userSpaceOnUse'>
                        <feOffset dy='12' />
                        <feGaussianBlur stdDeviation='10' result='blur' />
                        <feFlood floodOpacity='0.502' />
                        <feComposite operator='in' in2='blur' />
                        <feComposite in='SourceGraphic' />
                    </filter>
                </defs>
                <g transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(#Play_icon)'>
                    <path
                        id='Play_icon-2'
                        data-name='Play icon'
                        d='M107.329-.8,55.988-32.01a7.014,7.014,0,0,0-10.414,6.126V34.791a7.018,7.018,0,0,0,10.414,6.126l51.341-29.463A7.024,7.024,0,0,0,107.329-.8Zm38.593,5.251A72.344,72.344,0,1,0,73.578,76.8,72.331,72.331,0,0,0,145.922,4.453Zm-130.685,0A58.342,58.342,0,1,1,73.578,62.795,58.326,58.326,0,0,1,15.236,4.453Z'
                        transform='translate(28.77 85.89)'
                        fill='#fff'
                        opacity='0.5'
                    />
                </g>
            </svg>
        </>
    )
}

export default PlayIcon

/* eslint-disable react/display-name */
import { forwardRef, Ref } from 'react'

import { Video as VideoTypes } from '@/types/video'

const Video = forwardRef(
  (
    { data, ...rest }: { data: VideoTypes; controls?: boolean },
    ref?: Ref<HTMLVideoElement>,
  ) => (
    <video ref={ref} className="w-full h-full" {...rest}>
      {!data.formats || data.formats === null ? (
        <source src={data.url} />
      ) : (
        data.formats?.map((video: any, index: number) => (
          <source src={video.video_format.data.attributes.url} key={index} />
        ))
      )}
    </video>
  ),
)

Video.displayName = 'Video'

export { Video }

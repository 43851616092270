// @ts-nocheck
import { Fragment, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { Tab } from '@headlessui/react'

import { TabsProps } from '@/types/tabs'
import { VideoPlayerTypes } from '@/types/video-gallery-placeholder'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useWebsite } from '@/hooks/use-website'
import { useHash } from '@/hooks/use-hash'
import { useRouter } from 'next/router'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { IFrame } from '@/atoms/iframe'

import { ContentFullWidth } from '@/molecules/content-full-width'
import { ContentSplitCopyImage } from '@/molecules/content-with-image'
import { ContentTwoColumn } from '@/molecules/content-two-column'
import { ContentWithGallery } from '@/molecules/content-with-gallery'
import { ContentWithMap } from '@/molecules/content-with-map'
import { FullWidthGallery } from '@/molecules/full-width-gallery'
import { VideoPlayer } from '@/molecules/video-player'

export function TabbedContent({
  data,
  id,
  ...rest
}: {
  data: TabsProps
  id?: string
}) {
  const website = useWebsite()
  const router = useRouter()
  const { panels, theme, show_heading, strapline } = data

  const { hash, getHash, setHash } = useHash({})

  const [headings, setHeadings] = useState({
    mainHeading: '',
    strapline: '',
  })

  const [selectedIndex, setSelectedIndex] = useState(() => {
    let idx = 0

    panels.map((panel, index) => {
      if (getHash(panel.heading) === hash) {
        idx = index
      }
    })

    if (show_heading) {
      setHeadings({
        mainHeading: panels[idx].main_heading
          ? panels[idx].main_heading
          : panels[idx].heading,
        strapline: panels[idx].strapline ? panels[idx].strapline : strapline,
      })
    }

    return idx
  })

  useEffect(() => {
    const splitPath = router.asPath.split('#')

    if (splitPath.length > 1) {
      const idx = panels.findIndex(
        (panel) => getHash(panel.heading) === splitPath[1],
      )
      setSelectedIndex(idx !== null ? idx : 0)
    }
  }, [router.asPath])

  return (
    <Container
      id={id}
      variant="full"
      as="div"
      css={[theme === 'accent' && tw`bg-theme-gold bg-diamonds-pattern`]}>
      <Container {...rest}>
        {show_heading ? (
          <div tw="pt-14 mb-10">
            <p tw="leading-6 tracking-wide">{headings.strapline}</p>
            <Heading
              as="h2"
              variant="h2"
              css={[
                theme === 'accent' && tw`text-white text-3xl sm:text-5xl mb-10`,
                website === 'crockfords' &&
                  theme === 'default' &&
                  tw`text-theme-accent tracking-normal`,
              ]}>
              {headings.mainHeading}
            </Heading>
          </div>
        ) : null}

        <Tab.Group
          as="div"
          selectedIndex={selectedIndex}
          onChange={(index) => {
            // setTimeout(() => {
            setHash(getHash(panels[index].heading))
            // }, 1500)
            setSelectedIndex(index)

            // only set heading if show_heading is true
            show_heading &&
              setHeadings({
                mainHeading: panels[index].main_heading
                  ? panels[index].main_heading
                  : panels[index].heading,
                strapline: panels[index].strapline
                  ? panels[index].strapline
                  : strapline,
              })
          }}>
          <Tab.List
            css={[
              tw`mt-8 flex relative -mr-5 pr-5 md:(mr-0 pr-0) overflow-x-scroll`,
              website === 'crockfords' &&
                theme === 'accent' &&
                tw`font-baskerville text-theme-primary`,
              css`
                &[role='tablist'] {
                  -ms-overflow-style: none;
                  &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                    background: transparent;
                  }
                }
              `,
            ]}>
            {panels.map(({ heading, id }) => {
              return (
                <Tab as={Fragment} key={heading + id}>
                  {({ selected }) => (
                    <button
                      data-name={getHash(heading)}
                      css={[
                        tw`lg:text-lg py-3 px-0 ml-8 border-b-[5px] border-transparent relative z-20 whitespace-nowrap first:ml-0`,
                        website === 'crockfords' && tw`ml-3`,
                        selected && tw`font-bold border-black`,
                        selected &&
                          website === 'the-palm-beach' &&
                          tw`border-black`,
                        website === 'crockfords' &&
                          theme === 'accent' &&
                          selected &&
                          tw`border-white`,
                        css`
                          &::before {
                            display: block;
                            content: attr(data-name);
                            font-weight: 700;
                            height: 0;
                            overflow: hidden;
                            visibility: hidden;
                          }
                        `,
                      ]}>
                      <span id={getHash(heading)}>
                        <RenderMarkdown
                          css={
                            website === 'crockfords' && tw`font-baskerville`
                          }>
                          {heading}
                        </RenderMarkdown>
                      </span>
                    </button>
                  )}
                </Tab>
              )
            })}
          </Tab.List>
          <hr
            css={[
              tw`relative top-[-3px] -mr-5 md:(mr-0)`,
              website === 'the-palm-beach' && tw`border border-black`,
            ]}
          />
          <Tab.Panels tw="pt-10">
            {panels.map(({ tab }) => (
              <Tab.Panel key={tab.id}>
                {tab.data.attributes.panel_content?.map((item, index) => {
                  if (item.__component === 'content.copy-with-image')
                    return (
                      <div tw="-mx-5 md:-mx-10" key={`${tab.id}-${index}`}>
                        <ContentSplitCopyImage data={item} />
                      </div>
                    )
                  if (item.__component === 'casinos.copy-with-map')
                    return (
                      <ContentWithMap data={item} key={`${tab.id}-${index}`} />
                    )
                  if (item.__component === 'content.full-width-copy')
                    return (
                      <div tw="-mx-5 md:-mx-10" key={`${tab.id}-${index}`}>
                        <ContentFullWidth data={item} />
                      </div>
                    )
                  if (item.__component === 'content.two-column-copy')
                    return (
                      <div tw="-mx-5 md:-mx-10" key={`${tab.id}-${index}`}>
                        <ContentTwoColumn data={item} />
                      </div>
                    )
                  if (item.__component === 'casinos.gallery')
                    return (
                      <FullWidthGallery
                        data={item}
                        key={`${tab.id}-${index}`}
                      />
                    )
                  if (item.__component === 'casinos.copy-with-gallery')
                    return (
                      <ContentWithGallery
                        data={item}
                        key={`${tab.id}-${index}`}
                      />
                    )
                  if (item.__component === 'content.iframe')
                    return (
                      <div tw="border border-white" key={`${tab.id}-${index}`}>
                        <IFrame data={item as IframeTypes} />
                      </div>
                    )

                  if (item.__component === 'content.video-player')
                    return (
                      <VideoPlayer
                        data={item as VideoPlayerTypes}
                        key={`${tab.id}-${index}`}
                      />
                    )
                })}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </Container>
  )
}

import { ReactNode, ReactElement } from 'react'
import tw, { css, theme } from 'twin.macro'

const gaps = theme('gap')

const styles = {
  grid: ({gap, cols} : { gap: number, cols: number }) => {    
    return [
      tw`block md:(grid)`,
      css`
        gap: ${gaps[gap]};
        grid-template-columns: repeat(${cols}, 1fr);
      `,
    ]     
  }
}

type GridProps = {
  gap: number,
  cols: number,
  children: ReactNode
}

type ItemProps = {
  children: ReactElement
}

function Item({ children } : ItemProps) {
  return children
}

function Grid({ gap, cols, children } : GridProps) {
  return (
    <div css={styles.grid({gap, cols})}>      
      {children}
    </div>
 )
}

Grid.Item = Item

export { Grid}
import tw from 'twin.macro'

import { ContentFullWidth as ContentFullWidthTypes } from '@/types/content-full-width'

import { RenderMarkdown } from '@/lib/render-markdown'

import { Typography } from '@/atoms/typography'
import { Container } from '@/atoms/container'

function ContentFullWidth({ data, className }: { data: ContentFullWidthTypes, className?: string }) {
  return (
    <Container className={`pb-2 ${className}`}>
      <Typography>
        {/* @ts-ignore */}
        <RenderMarkdown>{data.copy}</RenderMarkdown>
      </Typography>
    </Container>
  )
}

export { ContentFullWidth }

import Image from 'next/image'
import { css } from 'twin.macro'

import { CoverImageProps } from '@/types/cover-image'

function CoverImage({ src, alt, position, sizes, ...rest }: CoverImageProps) {
    return (
            <Image
                src={src}
                alt={alt}
                layout="fill"
                sizes={sizes}
                loading="lazy"
                objectFit="cover"
                css={[
                    css`
                        background-position: ${position};
                    `,
                ]}
                {...rest}
            />
    )
}

export { CoverImage }

import { useRef, useState } from 'react'
import Image from 'next/image'

import tw from 'twin.macro'

import { Video as VideoTypes } from '@/types/video'

import PlayIcon from '@/atoms/play-icon'

import { Video } from '@/molecules/video'

function VideoPlayer({ data }: { data: VideoTypes }) {
  const [playVideo, setPlayVideo] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleVideo = () => {
    setPlayVideo(true)
    videoRef.current?.play()
    if (videoRef.current)
      return videoRef.current.addEventListener('ended', () =>
        setPlayVideo(false),
      )
  }

  return (
    <div tw="relative inline-block w-full">
      <Video ref={videoRef} data={data} controls />
      {data.placeholder && !playVideo && (
        <Image src={data.placeholder.url} layout="fill" objectFit="cover" />
      )}
      {!playVideo && (
        <PlayIcon
          tw="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2  w-28 md:w-48 cursor-pointer z-20"
          onClick={handleVideo}
        />
      )}
    </div>
  )
}

export { VideoPlayer }

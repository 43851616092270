import tw from 'twin.macro'

function EventStrapline({
  strapline,
  promotions_category,
  tournament_category,
  live_event_category,
}: {
  strapline: string
  promotions_category: { data: { attributes: { title: string } } }
  tournament_category: { data: { attributes: { title: string } } }
  live_event_category: { data: { attributes: { title: string } } }
}) {
  if (strapline) {
    return <p tw="text-sm text-theme-secondary capitalize">{strapline}</p>
  }

  return (
    <p tw="text-sm text-theme-secondary capitalize">
      <span className="mr-0.5">
        {promotions_category?.data && promotions_category.data.attributes.title}
      </span>
      <span className="mr-0.5">
        {tournament_category?.data && tournament_category.data.attributes.title}
      </span>
      {live_event_category?.data && live_event_category.data.attributes.title}
    </p>
  )
}

export { EventStrapline }

import tw from 'twin.macro'
import JackpotTabSummary from './jackpot-tab-summary'
import { AHref } from '@/atoms/a-href'
import JackpotTabInfo from './jackpot-tab-info'
import { useRouter } from 'next/router'

import { JackpotGames } from '@/types/atlas-jackpots'

type JackpotTabSummaryType = {
  data: {
    heading: string
    summary: string
    jackpots_cta: {
      text: string
      url: string
    }
    jackpots_info: {
      text: string
      image: {
        data?: {
          attributes: {
            url: string
            alternativeText: string
            width: string
            height: string
          }
        }
      }[]
    }
    terms_and_conditions: {
      id: number
      clip: string
      summary: string
      link: {
        id: number
        label: string
        url: string
      }
    }[]
  }
}

const JackpotTab = ({ data, jackpots }: {data: JackpotTabSummaryType, jackpots: JackpotGames}) => {
  const { heading, summary, jackpots_info, terms_and_conditions } = data
  const {
    query: { slug },
  } = useRouter()

  return (
    <div className="grid md:grid-cols-3 gap-10 items-end">
      <JackpotTabSummary data={{ heading, summary, terms_and_conditions }} />
      <JackpotTabInfo data={jackpots_info} jackpots={jackpots} />
      { data.jackpots_cta &&
        data.jackpots_cta.url &&
        data.jackpots_cta.text &&
        slug === 'home-genting-casinos' && (
          <AHref
            to={data.jackpots_cta.url}
            theme="default"
            variant="inverse"
            tw="px-8 border-2 min-w-[220px] justify-self-center select-none">
            {data.jackpots_cta.text}
          </AHref>
        )}
        
    </div>
  )
}

export default JackpotTab

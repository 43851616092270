import React, { useEffect, useState, ReactNode } from 'react'
import tw, { css } from 'twin.macro'

import { Spinner } from '@/atoms/spinner'

import { Jackpot } from '@/types/atlas-jackpots'

const JackpotValue = ({
  text,
  jackpot,
}: {
  text: string | ReactNode
  jackpot: Jackpot | null
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(jackpot === null || jackpot === undefined)
  }, [jackpot])

  const getJackpotValue = () => {
    if (!jackpot.success) return 'Unable to load jackpot value'

    return (jackpot.value / 100).toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    })
  }

  return (
    <div tw="flex flex-col gap-1 items-center mt-7 md:mt-0">
      <p tw="text-lg">{text}</p>
      <div
        css={[
          tw`text-2xl border border-2 border-white rounded-full bg-theme-gold px-10 py-2 font-medium min-w-[220px] text-center`,
          jackpot && !jackpot.success && tw`text-red-600 text-base font-normal`,
        ]}>
        {loading ? (
          <Spinner size="small" margin="none" />
        ) : jackpot && jackpot.success ? (
          getJackpotValue()
        ) : (
          <span>Unable to load jackpot value</span>
        )}
      </div>
    </div>
  )
}

export default JackpotValue

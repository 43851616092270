import { IFrame as IFrameTypes } from '@/types/iframe'

import IframeResizer from 'iframe-resizer-react'

type IframeElement = {
	iframe: {
		offsetTop: number
	}
}

export function IFrame({ data, id }: { data: IFrameTypes, id?: string }) {
	const onResized = ({ iframe }: IframeElement) => {
		if (window.scrollY > iframe.offsetTop) {
			window.scroll({
				top: iframe.offsetTop,
				behavior: 'smooth',
			})
		}
	}

	return (
		<IframeResizer
			id={id}
			width='100%'
			height={data.fixed_height && `${data.height}px`}
			src={data.source}
			heightCalculationMethod='lowestElement'
			sizeHeight={data.fixed_height ? false : true}
			bodyPadding='20px 0'
			onResized={onResized}
			className='min-w-full'>
		</IframeResizer>
	)
}

import { useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { useRouter } from 'next/router'

import { ContentWithImage } from '@/types/content-with-image'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useSingle } from '@/hooks/use-cms-data'

import { Grid } from '@/atoms/grid'
import { Typography } from '@/atoms/typography'
import { GoogleMap } from '@/components/molecules/google-map'
import { useWebsite } from '@/hooks/use-website'

function ContentWithMap({ data }: { data: ContentWithImage }) {
  const website = useWebsite()
  const { query } = useRouter()

  const casinoQuery = {
    filters: {
      slug: website === 'genting-casinos' ? query.slug : website,
    },
  }
  const { data: casinoData } = useSingle('casinos', casinoQuery)
  const { content_text } = data

  return (
    <div>
      <div className="my-6 md:my-12">
        <Grid gap={20} cols={2}>
          <Grid.Item>
            <Typography>
              {/* @ts-ignore */}
              <RenderMarkdown>{content_text}</RenderMarkdown>
            </Typography>
          </Grid.Item>
          <Grid.Item>
            {casinoData?.data && casinoData.data.length > 0 && (
              <div tw="mt-6 md:mt-0 w-full h-[275px] md:h-[424px] border border-theme-secondary">
                <GoogleMap
                  options={{
                    zoom: 14,
                    center: {
                      lat: casinoData.data[0].attributes.latitude,
                      lng: casinoData.data[0].attributes.longitude,
                    },
                  }}
                  label={casinoData.data[0].attributes.order.toString()}
                />
              </div>
            )}
          </Grid.Item>
        </Grid>
      </div>
    </div>
  )
}

export { ContentWithMap }

import React, { ReactNode } from 'react'
import tw from 'twin.macro'

const InfiniteScrollingBanner = ({ children, ...rest }: { children: ReactNode }) => {
    return (
        <div tw="flex flex-row w-full py-4 mb-4 px-2 overflow-hidden whitespace-nowrap" {...rest}>
            <div tw="animate-infinite-scroll">
                { children } { children } { children }
            </div>
            <div tw="animate-infinite-scroll delay-[15000ms]">
                { children } { children } { children }
            </div>
        </div>
    )
}

export default InfiniteScrollingBanner

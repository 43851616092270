// @ts-nocheck
import tw, { css } from 'twin.macro'
import { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Tab } from '@headlessui/react'

import { TabsProps } from '@/types/tabs'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useWebsite } from '@/hooks/use-website'
import { useHash } from '@/hooks/use-hash'
import { useStoredJackpots } from '@/hooks/use-stored-jackpots'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'

import JackpotTab from '@/components/molecules/jackpot-tab'
import InfiniteScrollingBanner from '@/atoms/infinite-scrolling-banner'

const styles = {
  tab: [
    tw`relative uppercase text-white text-center text-lg lg:text-2xl font-light focus:outline-none`,
    tw`whitespace-nowrap inline-block w-auto p-5 border-b-4 border-[#72674B]`,
    tw`md:hover:(text-white border-white)`,
    css`
      &[aria-selected='true'] {
        color: white;
        font-weight: 400;
        border-color: white;
      }
      ,
      &::before {
        display: block;
        content: attr(data-name);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    `,
  ],
  tabList: [
    tw`flex bg-transparent overflow-y-auto`,
    css`
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }
    `,
  ],
}

export function JackpotTabs({
  data,
  id,
  ...rest
}: {
  data: TabsProps
  id?: string
}) {
  const website = useWebsite()
  const router = useRouter()

  const { panels, heading, strapline, national_jackpot_banner } = data
  const { nationalJackpots, lastUpdated, getGameByName } = useStoredJackpots(
    router.query.slug,
    id,
  )

  const { hash, getHash, setHash } = useHash()

  const [selectedIndex, setSelectedIndex] = useState(() => {
    let idx = 0

    panels.map((panel, index) => {
      if (getHash(panel.heading) === hash) {
        idx = index
      }
    })

    return idx
  })

  useEffect(() => {
    const splitPath = router.asPath.split('#')

    if (splitPath.length > 1) {
      const idx = panels.findIndex(
        (panel) => getHash(panel.heading) === splitPath[1],
      )
      setSelectedIndex(idx !== null ? idx : 0)
    }
  }, [router.asPath])

  const animatonUrl =
    panels[selectedIndex]?.jackpot.data[0].attributes.panel_content[0]
      ?.animation.data?.attributes.url

  function formattedDateTime() {
    const date = new Date(lastUpdated)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${hours}:${minutes} ${day}/${month}/${year}`
  }

  return (
    <Container
      id={id}
      variant="full"
      as="section"
      css={[
        tw`bg-theme-gold bg-diamonds-pattern relative`,
        css`
          background-size: 230px;
        `,
      ]}>
      {national_jackpot_banner && nationalJackpots.length > 0 && (
        <InfiniteScrollingBanner tw="bg-white text-theme-gold tracking-wider flex flex-row items-center">
          {nationalJackpots.map((jackpot: string, i: number) => {
            return (
              <Fragment key={`jackpot-banner-text-${i}`}>
                <span>{jackpot.name} National Jackpot: </span>
                <span tw="font-bold">
                  {(jackpot.value / 100).toLocaleString('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                  })}
                </span>
                <span tw="font-bold px-4 text-2xl">&middot;</span>
              </Fragment>
            )
          })}
        </InfiniteScrollingBanner>
      )}
      <Container {...rest}>
        <div tw="pt-10 text-center">
          {strapline && (
            <p tw="leading-6 tracking-wide text-left mb-1">{strapline}</p>
          )}
          <Heading
            as="h2"
            variant="h2"
            css={[
              tw`text-4.5xl lg:text-6xl mb-4`,
              strapline && tw`text-left text-4xl lg:text-4.5xl`,
            ]}>
            {heading}
          </Heading>
        </div>
        <Tab.Group
          as="div"
          selectedIndex={selectedIndex}
          onChange={(index) => {
            // setTimeout(() => {
            setHash(getHash(panels[index].heading))
            // }, 1500)
            setSelectedIndex(index)
          }}>
          <div tw="flex justify-center relative z-10">
            <Tab.List css={styles.tabList}>
              {panels.map(({ heading, id }) => {
                return (
                  <Tab as={Fragment} key={heading + id}>
                    <button data-name={getHash(heading)} css={styles.tab}>
                      <span id={getHash(heading)}>
                        <RenderMarkdown
                          css={
                            website === 'crockfords' && tw`font-baskerville`
                          }>
                          {heading}
                        </RenderMarkdown>
                      </span>
                    </button>
                  </Tab>
                )
              })}
            </Tab.List>
          </div>
          <Tab.Panels tw="py-12 relative z-10">
            {panels.map(({ jackpot }, index) => (
              <Tab.Panel key={`jackpot-tab-panel-${index}`}>
                {jackpot.data.map(({ attributes }, index) => {
                  const { __component } = attributes.panel_content[0]
                  if (
                    __component === 'jackpots.b7s' ||
                    __component === 'jackpots.tcp-stud'
                  ) {
                    const key = __component.split('.')[1]
                    const jackpots =
                      getGameByName(key, router.query.slug) ?? null

                    return (
                      <JackpotTab
                        key={`jackpot-tab-${index}`}
                        data={attributes.panel_content[0]}
                        jackpots={jackpots}
                      />
                    )
                  }
                })}
              </Tab.Panel>
            ))}
            {lastUpdated !== '' && (
              <p tw="text-xs mt-10 text-center">
                {`Correct as of: ${formattedDateTime()}`}
              </p>
            )}
          </Tab.Panels>
        </Tab.Group>
      </Container>
      {animatonUrl && (
        <img
          src={animatonUrl}
          alt="jackpoyt animation"
          tw="absolute inset-0 min-h-[100%] object-cover object-center opacity-70 z-0"
        />
      )}
    </Container>
  )
}

import tw, { css } from 'twin.macro'

import { RenderMarkdown } from '@/lib/render-markdown'

import { Heading } from '@/atoms/headings'
import { Typography } from '@/atoms/typography'
import { useRouter } from 'next/router'

type JackpotTabSummaryType = {
    data: {
        heading: string,
        summary: string
    }
}

const JackpotTabSummary = ({ data }: JackpotTabSummaryType ) => {
    const { heading, summary } = data

    const router =  useRouter()
    const isCasinoPage = router.pathname.includes('/casinos/') 
    
    return (
        <div css={[tw`flex flex-col gap-7 items-center md:items-start text-center md:text-left`, isCasinoPage && tw`items-start text-left`]} >
            <Heading variant='h3' tw='md:mt-10'>
                {heading}
            </Heading>
            <hr tw="w-full" />
            <Typography theme='accent' css={[css`line-height: 26px;`, tw`text-lg font-light`]}>
                {/* @ts-ignore */}
                <RenderMarkdown>{summary}</RenderMarkdown>
            </Typography>
        </div>
    )
}

export default JackpotTabSummary
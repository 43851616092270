import Image from 'next/image'
import tw, { css } from 'twin.macro'

import { ContentWithImage } from '@/types/content-with-image'

import { RenderMarkdown } from '@/lib/render-markdown'

import useBookNow from '@/hooks/use-book-now'

import { NextLink } from '@/atoms/next-link'
import { Grid } from '@/atoms/grid'
import { Typography } from '@/atoms/typography'
import { Container } from '@/atoms/container'

import { CTAButtons } from '@/molecules/cta-buttons'
import { Video } from '@/molecules/video'

function ContentSplitCopyImage({ data }: { data: ContentWithImage }) {
  const { theme: sectionTheme, content_text, content_image, cta_buttons } = data
  const { event, pathPrefix, isWhatsOn, slug } = useBookNow()

  const renderImage = () => {
    return content_image?.data?.attributes?.mime?.includes('video/') ? (
      <div tw="relative aspect-w-16 aspect-h-9 h-0">
        <Video
          /* @ts-ignore */
          data={content_image.data.attributes}
          controls
          tw="absolute top-0 left-0 object-cover xl:h-full"
        />
      </div>
    ) : (
      <div className="mt-6 md:mt-0 flex items-start">
        <div
          css={[
            tw`w-full`,
            css`
              > span {
                position: unset !important;
              }
            `,
          ]}>
          {content_image?.data?.attributes.url && (
            <Image
              src={content_image.data.attributes.url}
              alt={content_image.data.attributes.alternativeText}
              layout="fill"
              css={[
                tw`object-contain`,
                css`
                  width: 100% !important;
                  position: relative !important;
                  height: unset !important;
                `,
              ]}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <Container tw="mx-0">
      <div tw="mb-6">
        <Grid gap={20} cols={2}>
          <Grid.Item>
            <div>
              <Typography>
                {/* @ts-ignore */}
                <RenderMarkdown>{content_text}</RenderMarkdown>
              </Typography>
              {isWhatsOn &&
              event?.attributes?.live_res_occasion_id?.replace(/\s/g, '')
                .length ? (
                <NextLink
                  to={`${pathPrefix}/casinos/bookings/${slug}`}
                  tw="bg-theme-accent py-2 px-4 min-w-[200px] text-white text-center inline-block uppercase">
                  Book Now
                </NextLink>
              ) : null}
              {cta_buttons?.length ? (
                <CTAButtons buttons={cta_buttons} theme={sectionTheme} />
              ) : null}
            </div>
          </Grid.Item>
          <Grid.Item>{content_image && <>{renderImage()}</>}</Grid.Item>
        </Grid>
      </div>
    </Container>
  )
}

export { ContentSplitCopyImage }

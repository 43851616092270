import tw from 'twin.macro'

type Size = 'small' | 'medium' | 'large';
type Theme = 'default' | 'accent';
type Margin = 'none' | 'small' | 'medium' | 'large';

type Props = {
  size?: Size,
  theme?: Theme,
  margin?: Margin,
};

const styles = {
  root: tw`inline-block`,
  spinner: (size: Size, theme: Theme, margin: Margin) => [
    tw`animate-spin`,
    theme === 'accent' ? tw`text-theme-accent` : tw`text-white`,
    size === 'small' && tw`w-4 h-4`,
    size === 'medium' && tw`w-6 h-6`,
    size === 'large' && tw`w-8 h-8`,
    margin === 'small' && tw`m-2.5`,
    margin === 'medium' && tw`m-5`,
    margin === 'large' && tw`m-10`,
  ],
};

function Spinner({ size = 'medium', theme = 'default', margin = 'medium' }: Props) {
  return (
    <div css={styles.root}>
      <span className="sr-only">Loading</span>
      <svg css={styles.spinner(size, theme, margin)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" aria-hidden>
        <circle opacity=".25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path opacity=".75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  )
}

export { Spinner }

import { forwardRef, ReactElement, ReactNode } from 'react'
import tw, { css } from 'twin.macro'

type Props = {
  children: ReactNode
  showScrollBar?: boolean
}

type Ref = HTMLDivElement

const Slider = forwardRef<Ref, Props>(function Slider(
  { children, showScrollBar = true, ...rest },
  ref,
) {
  return (
    <div
      data-testid="slider"
      ref={ref}
      css={[
        tw`overflow-x-scroll`,
        css`
          scroll-snap-type: x mandatory;
          scrollbar-color: white;
          scrollbar-width: ${showScrollBar ? 'thin' : 'none'};
          scrollbar-height: 5px;
          &::-webkit-scrollbar {
            width: 0px;
            height: ${showScrollBar ? '5px' : '0px'};
          }

          &::-webkit-scrollbar-track {
            background: black;
          }
          &::-webkit-scrollbar-thumb {
            background-color: white;
            border-radius: 0;
            border: none;
          }
        `,
      ]}
      {...rest}>
      {children}
    </div>
  )
})

function SliderItem({
  children,
  ...rest
}: {
  tabIndex?: number
  children: ReactElement | ReactElement[]
}) {
  return (
    <div
      css={[
        css`
          scroll-snap-align: start;
        `,
      ]}
      {...rest}>
      {children}
    </div>
  )
}

export { Slider, SliderItem }

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export function useHash() {
  const router = useRouter()
  const [hash, setHashValue] = useState(() => {
    if (router.asPath.includes('#')) {
      return router.asPath.split('#')[1]
    } else {
      return ''
    }
  })

  useEffect(() => {
    function handleHashChange() {
      const hash = window.location.hash?.replace('#', '')
      setHashValue(hash)
    }

    window.addEventListener('hashchange', handleHashChange, false)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  function getHash(value: string) {
    return value.toLowerCase().trim().replace(/ /g, '-').replace(/[’]/g, '')
  }

  function setHash(value: any) {
    router.push({ hash: getHash(value) })
  }

  return {
    hash,
    getHash,
    setHash,
  }
}

import { FAQ_Question } from '@/types/faq_types'
import { Disclosure } from '@headlessui/react'
import { RenderMarkdown } from '@/lib/render-markdown'
import { Typography } from './typography'

export function FaQuestionDisclosure({ data }: { data: FAQ_Question[] }) {
  return (
    <>
      {data.length &&
        data.map((question) => {
          return (
            <div
              className="w-full flex flex-col items-center border-b border-gray-100"
              key={question.id}>
              <Disclosure>
                {({ open }) => (
                  <div className="w-full">
                    <Disclosure.Button className="pt-5 pb-4 w-full flex flex-row justify-between">
                      <div>{question.attributes.title}</div>
                      <div>{open ? '-' : '+'}</div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-theme-primary pb-6 font-light">
                      <Typography theme="accent">
                        {/* @ts-ignore */}
                        <RenderMarkdown>
                          {question.attributes.answer}
                        </RenderMarkdown>
                      </Typography>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            </div>
          )
        })}
    </>
  )
}

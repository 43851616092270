import { ReactNode } from 'react'
import tw from 'twin.macro'
import Image from 'next/image'

import { usePathPrefix } from '@/hooks/use-path-prefix'

import { NextLink } from '@/atoms/next-link'
import { Heading } from '@/atoms/headings'
import { CoverImage } from '@/atoms/cover-image'

import { Tile } from '@/types/tile'

function GamingTileHeading({ children, ...rest }: { children: ReactNode }) {
  return (
    <Heading
      as="h4"
      variant="h4"
      tw="w-full p-4 text-base leading-none h-full flex items-center justify-center"
      {...rest}>
      {children}
    </Heading>
  )
}

function GamingTile({
  attributes: { title, slug, image, foreground },
  children,
}: Tile) {
  const pathPrefix = usePathPrefix()

  return (
    <NextLink
      to={`${pathPrefix}/casino-gaming/${slug}`}
      tw="grid bg-theme-secondary border cursor-pointer w-full grid-rows-[11rem 1fr]">
      <div
        title="Learn more"
        className="relative row-start-1 row-span-3 col-start-1 w-full h-full z-10">
        {image?.data && (
          <CoverImage
            tw="w-full h-full col-span-full row-span-full z-20 relative"
            src={image.data.attributes.url}
            alt={image.data.attributes.alternativeText || `${title} image`}
            sizes="320px"
          />
        )}
        {foreground?.data && (
          <Image
            tw="w-full h-full col-span-full row-span-full z-20 relative"
            src={foreground.data.attributes.url}
            alt={foreground.data.attributes.alternativeText || `${title} image`}
            layout="fill"
            objectFit="contain"
          />
        )}
      </div>
      <div tw="flex justify-center items-center uppercase text-center">
        {children}
      </div>
    </NextLink>
  )
}

GamingTile.Heading = GamingTileHeading

export { GamingTile }

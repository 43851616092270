import tw, { css, theme } from 'twin.macro'

import { GalleryImage } from '@/types/gallery-types'
import { ContentWithGallery as ContentWithGalleryTypes } from '@/types/content-with-gallery'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useWebsite } from '@/hooks/use-website'

import { Gallery } from '@/atoms/gallery'
import { Typography } from '@/atoms/typography'

import { CTAButtons } from '@/molecules/cta-buttons'

function ContentWithGallery({ data }: { data: ContentWithGalleryTypes }) {
  const { theme: sectionTheme, copy, gallery, cta_buttons } = data
  const website = useWebsite()

  return (
    <div
      css={[
        tw`grid grid-template-columns[1fr] lg:(grid-template-columns[40% 60%])`,
        css`
          grid-template-areas:
            'copy'
            'cta'
            'gallery';

          @media (min-width: ${theme`screens.lg`}) {
            grid-template-areas:
              'copy gallery'
              'cta gallery';
          }
        `,
      ]}>
      <div
        css={[
          css`
            grid-area: copy;
          `,
          tw`my-5 md:mb-5`,
        ]}>
        <Typography theme={sectionTheme}>
          {/* @ts-ignore */}
          <RenderMarkdown>{copy}</RenderMarkdown>
        </Typography>
      </div>
      {gallery && gallery?.images?.data && (
        <div
          css={[
            tw`md:mt-4 lg:pl-16`,
            css`
              grid-area: gallery;
            `,
          ]}>
          <Gallery images={gallery.images.data}>
            <div tw="-mx-5 grid gap-x-5 grid-template-columns[54px 1fr 54px] md:(grid-template-columns[40px 1fr 40px] border-none)">
              <Gallery.Previous
                fill={
                  website === 'crockfords' && sectionTheme === 'default'
                    ? theme`colors.theme.accent`
                    : '#fff'
                }
                css={[
                  tw`justify-self-end md:justify-self-start`,
                  website === 'crockfords' &&
                    sectionTheme === 'default' &&
                    tw`text-theme-accent`,
                  css`
                    @media (hover: hover) and (pointer: fine) {
                      &:hover {
                        color: #9a8757;
                      }
                    }
                  `,
                ]}
              />
              <Gallery.Next
                fill={
                  website === 'crockfords' && sectionTheme === 'default'
                    ? theme`colors.theme.accent`
                    : '#fff'
                }
                css={[
                  tw`justify-self-start md:justify-self-end`,

                  css`
                    @media (hover: hover) and (pointer: fine) {
                      &:hover {
                        color: #9a8757;
                      }
                    }
                  `,
                ]}
              />
              <Gallery.Main
                css={[
                  tw`border-t border-b md:(border)`,
                  website === 'genting-casinos' && tw`border-white`,
                  website === 'the-palm-beach' && tw`border-black`,
                  website === 'crockfords' &&
                    sectionTheme === 'default' &&
                    tw`border-theme-accent`,
                ]}>
                {gallery.images.data.map(
                  ({ id, name, attributes }: GalleryImage) => (
                    <Gallery.Item
                      id={id}
                      name={name}
                      attributes={attributes}
                      key={id}
                      tw="h-[360px] w-full"
                    />
                  ),
                )}
              </Gallery.Main>
            </div>

            <div tw="mt-5 md:(grid gap-x-5 grid-template-columns[22px 1fr 22px])">
              <div tw="col-start-2 col-span-1 overflow-visible lg:overflow-hidden">
                <Gallery.Thumbnails
                  css={[
                    tw`col-start-2 col-span-1 flex flex-nowrap`,
                    css`
                      div:last-of-type {
                        margin-right: 0;
                      }

                      margin-right: calc((100vw - 100%) * -0.5);
                      padding-right: calc((100vw - 100%) * 0.5);
                    `,
                  ]}>
                  {gallery.images.data.map(
                    ({ id, name, attributes }: GalleryImage, index) => (
                      <>
                        <Gallery.Thumbnail
                          index={index}
                          key={id}
                          name={name}
                          attributes={attributes}
                          css={[
                            tw`h-[46px] flex-shrink-0 mr-5 relative border hover:border-[3px] hover:border-white`,
                            website === 'genting-casinos' && tw`border-white`,
                            website === 'the-palm-beach' && tw`border-black`,
                            website === 'crockfords' &&
                              sectionTheme === 'default' &&
                              tw`border-theme-accent hover:border-theme-accent`,
                            css`
                              width: calc(calc(100% / 4));

                              @media (min-width: ${theme`screens.lg`}) {
                                width: calc(calc(100% / 6) - 16.7px);
                              }
                            `,
                          ]}
                        />
                      </>
                    ),
                  )}
                </Gallery.Thumbnails>
              </div>
            </div>
          </Gallery>
        </div>
      )}

      {cta_buttons ? (
        <CTAButtons
          buttons={cta_buttons}
          theme={sectionTheme}
          website={website}
        />
      ) : null}
    </div>
  )
}

export { ContentWithGallery }

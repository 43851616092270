import { useRouter } from 'next/router'
import { useCleanPathname } from './use-clean-path-name'
import { useSingle } from './use-cms-data'
import { usePathPrefix } from './use-path-prefix'

const useBookNow = () => {
    const {query: {slug}} = useRouter()
    const { data: event } = useSingle('events', { slug })
    const pathPrefix = usePathPrefix()
    const path = useCleanPathname()
    const isWhatsOn = path.includes('whats-on')

  return {event, pathPrefix, isWhatsOn, slug}
}

export default useBookNow
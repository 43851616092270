import React from 'react'
import tw from 'twin.macro'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { JackpotGames } from '@/types/atlas-jackpots'

import JackpotValue from '@/atoms/jackpot-value'

type JackpotTabInfoType = {
  text: string
  jackpot_value: string
  image: {
    data: {
      attributes: {
        url: string
        alternativeText: string
        width: number
        height: number
      }
    }
  }
}

type JackpotTabInfoContentType = {
  data: JackpotTabInfoType[]
  jackpots: JackpotGames
  loading: boolean
}

const JackpotTabInfo = ({ data, jackpots }: JackpotTabInfoContentType) => {
  const {
    query: { slug },
  } = useRouter()

  return (
    <>
      {data.map((col: JackpotTabInfoType, index) => (
        <div
          key={`jackpot-tab-info-${index}`}
          tw="flex flex-col items-center justify-between gap-5">
          {col.image.data && (
            <Image
              src={col.image?.data.attributes.url}
              alt={col.image?.data.attributes.alternativeText}
              width={col.image?.data.attributes.width}
              height={col.image?.data.attributes.height}
            />
          )}
          <JackpotValue
            text={col.text}
            jackpot={
              jackpots
                ? jackpots[
                    col.jackpot_value === 'national'
                      ? col.jackpot_value
                      : (slug as string)
                  ]
                : null
            }
          />
        </div>
      ))}
    </>
  )
}

export default JackpotTabInfo

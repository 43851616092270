import { useRouter } from 'next/router'

import { useWebsite } from '@/hooks/use-website'

type TOptions = {
  excludePagination?: boolean
  includeQuery?: boolean
}

const defaultOptions: TOptions = {
  excludePagination: false,
  includeQuery: false,
}

export function useCleanPathname(options = defaultOptions) {
  const website = useWebsite()
  const { asPath } = useRouter()
  const { excludePagination, includeQuery } = options

  let path = asPath

  if (includeQuery === false && path.includes('?')) {
    const asPathArr = path.split('?')
    path = asPathArr[0]
  }

  if (path.includes(website) && website === 'genting-casinos') {
    path = path.replace(`/${website}/`, '')
  }

  // replace pagination
  if (excludePagination === true) {
    path = `/${path
      .split('/')
      .filter((p) => {
        if (isNaN(Number.parseInt(p))) {
          return p
        }
      })
      .join('/')}`
  }

  return path
}

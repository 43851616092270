import { useEffect } from 'react'
import Script from 'next/script'
import tw from 'twin.macro'

import { useWebsite } from '@/hooks/use-website'

import mapStyle from '@/styles/map-styles'

export function GoogleMap({
  label,
  options,
}: {
  label: string
  options: {
    zoom?: number
    center: { lat: number; lng: number }
    mapTypeId?: google.maps.MapTypeId.ROADMAP
    disableDefaultUI?: boolean
    panControl?: boolean
    zoomControl?: boolean
    fullscreenControl?: boolean
  }
}) {
  const website = useWebsite()

  // if Google maps js is already loaded we can initialise map
  useEffect(() => {
    if (window && window.google) {
      initMap()
    }
  }, [])

  function initMap(): void {
    const mapOptions = {
      zoom: 6,
      center: { lat: 53.5, lng: -3.5 },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      panControl: true,
      zoomControl: true,
      styles: mapStyle[website],
      fullscreenControl: true,
      ...options,
    }

    // initialise the map after script loaded
    // @ts-ignore
    const map = new google.maps.Map(document.getElementById('map'), mapOptions)

    new google.maps.Marker({
      position: {
        lat: options.center.lat,
        lng: options.center.lng,
      },
      map,
      icon: {
        path: 'M12.2503.75c6.0012 0 10.5948 4.481 10.5948 10.4263v.3384c0 7.8346-6.9987 17.5823-10.7759 19.7353h-.0905C8.2015 29.097.75 19.3493.75 11.5147v-.3384C.75 5.231 5.7964.75 11.7975.75h.4528z',
        fillColor: ['the-palm-beach', 'crockfords'].includes(website) ? '#af9862' : '#7b030b',
        fillOpacity: 1,
        strokeWeight: 1.5,
        strokeColor: ['the-palm-beach', 'crockfords'].includes(website) ? '#1a1a1a' : '#7b030b',
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(11.79755, 32),
        labelOrigin: new google.maps.Point(11.79755, 11.79755),
      },
      label: {
        text: label,
        color: '#fff',
      },
    })
  }

  return (
    <>
      <Script
        id="map-js"
        strategy="afterInteractive"
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBXkPjGQ_JdlxeSVq9_dbz-SpJDP5q0fos&libraries=places"
        onLoad={initMap}
      />

      <div id="map" tw="w-full h-full"></div>
    </>
  )
}

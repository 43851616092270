import {
  createContext,
  createRef,
  useState,
  useContext,
  useLayoutEffect,
  ReactElement,
  ReactNode,
  Ref,
} from 'react'
import tw, { css } from 'twin.macro'

import { GalleryImage } from '@/types/gallery-types'

import { CoverImage } from '@/atoms/cover-image'
import { Slider, SliderItem } from '@/atoms/slider'
interface GalleryContextInterface {
  currentSlide: number
  images: GalleryImage[]
  nextSlide: () => void
  previousSlide: () => void
  scrollListRef: Ref<HTMLDivElement>
  scrollToSlide: (slideId: number) => void
}

const GalleryContext = createContext<GalleryContextInterface | null>(null)

function useGalleryContext() {
  const context = useContext(GalleryContext)

  if (!context) {
    throw new Error(
      `Gallery compound components cannot be rendered outside the Gallery component`
    )
  }
  return context
}

function PreviousButton({ fill = '#fff', ...rest }) {
  const { previousSlide, currentSlide } = useGalleryContext()

  return (
    <button
      onClick={previousSlide}
      css={[
        tw`flex items-center justify-center row-start-1 col-start-1 z-20 focus:(outline-none)`,
        currentSlide === 0 && tw`opacity-50`,
      ]}
      {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.621"
        height="64.243"
        viewBox="0 0 33.621 64.243"
        className=" fill-current">
        <path
          id="Left_arrow"
          data-name="Left arrow"
          d="M30,0,0,30,30,60"
          transform="translate(1.5 2.121)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </svg>
    </button>
  )
}

function NextButton({ fill = '#fff', ...rest }) {
  const { nextSlide } = useGalleryContext()
  return (
    <button
      onClick={nextSlide}
      css={[
        tw`flex items-center justify-center row-start-1 col-start-3 z-20 focus:(outline-none) lg:(mx-5)`, //-mr-4: unable to justify-self-end due to Icon having content beyond visual
      ]}
      {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.621"
        height="64.243"
        viewBox="0 0 33.621 64.243">
        <path
          id="Right_arrow"
          data-name="Right arrow"
          d="M0,0,30,30,0,60"
          transform="translate(1.5 2.121)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </svg>
    </button>
  )
}

function Main({ children, ...rest }: { children: ReactNode }) {
  const { scrollListRef } = useGalleryContext()

  return (
    <div
      {...rest}
      ref={scrollListRef}
      tw="flex flex-nowrap col-start-1 col-span-3 md:(mx-0 col-start-2 col-span-1) row-start-1 z-10 overflow-hidden lg:(mr-4)">
      {children}
    </div>
  )
}

function Item({ id, attributes, name, ...rest }: GalleryImage) {
  return (
    <SliderItem key={id} tw="relative flex-shrink-0 w-full" {...rest}>
      <CoverImage
        key={id}
        src={attributes.url}
        alt={attributes.alternativeText ? attributes.alternativeText : name}
      />
    </SliderItem>
  )
}

function Thumbnails({ children, ...rest }: { children: ReactNode }) {
  return (
    <Slider tw="flex flex-nowrap overflow-y-scroll" {...rest}>
      {children}
    </Slider>
  )
}

function Thumbnail({ id, attributes, name, index, ...rest }: GalleryImage) {
  const { scrollToSlide } = useGalleryContext()
  return (
    <SliderItem {...rest}>
      <div
        role="button"
        onClick={() => scrollToSlide(index)}
        tw="max-h-full overflow-hidden h-full">
        <CoverImage
          key={id}
          src={attributes.url}
          alt={attributes.alternativeText ? attributes.alternativeText : name}
        />
      </div>
    </SliderItem>
  )
}

let width: null | number = null
function elementObserver(scrollListRef, currentSlide) {
  if (typeof window === 'undefined') return null

  const observer = new ResizeObserver((entries) => {
    if (!scrollListRef?.current) return

    const newWidth = entries[0].contentRect.width

    if (newWidth !== width) {
      width = newWidth

      scrollListRef.current.scrollTo({
        left: scrollListRef.current.clientWidth * currentSlide,
        behavior: 'smooth',
      })
    }
  })

  return observer
}

function Gallery({
  images,
  children,
}: {
  images: GalleryImage[]
  children: ReactNode | ReactElement
}) {
  const scrollListRef = createRef<HTMLDivElement>()
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useLayoutEffect(() => {
    if (!scrollListRef) return

    const observer = elementObserver(scrollListRef, currentSlide)

    const element = scrollListRef.current

    observer?.observe(element)

    return () => {
      observer && observer.unobserve(element)
    }
  }, [scrollListRef, elementObserver, currentSlide])

  const previousSlide = () => {
    let newSlide = currentSlide - 1

    if (!scrollListRef?.current) {
      return
    }
    if (newSlide < 0) {
      newSlide = images.length - 1
    }
    scrollListRef.current.scrollTo({
      left: scrollListRef.current.clientWidth * newSlide,
      behavior: 'smooth',
    })

    setCurrentSlide(newSlide)
  }

  const nextSlide = () => {
    let newSlide = currentSlide + 1
    if (!scrollListRef?.current) {
      return
    }
    if (newSlide > images.length - 1) {
      newSlide = 0
    }

    scrollListRef.current.scrollTo({
      left: scrollListRef.current.clientWidth * newSlide,
      behavior: 'smooth',
    })

    setCurrentSlide(newSlide)
  }

  const scrollToSlide = (slideId: number) => {
    const newSlide = slideId

    if (!scrollListRef?.current || newSlide > images.length || newSlide < 0) {
      return
    }

    scrollListRef.current.scrollTo({
      left: scrollListRef.current.clientWidth * newSlide,
      behavior: 'smooth',
    })

    setCurrentSlide(newSlide)
  }

  return (
    <GalleryContext.Provider
      value={{
        previousSlide,
        currentSlide,
        nextSlide,
        images,
        scrollListRef,
        scrollToSlide,
      }}>
      {children}
    </GalleryContext.Provider>
  )
}

Gallery.Next = NextButton
Gallery.Previous = PreviousButton
Gallery.Main = Main
Gallery.Item = Item
Gallery.Thumbnails = Thumbnails
Gallery.Thumbnail = Thumbnail

export { Gallery }

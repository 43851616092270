import tw, { css, theme } from 'twin.macro'

import { GalleryImage } from '@/types/gallery-types'

import { Gallery } from '@/atoms/gallery'

export function FullWidthGallery({
  data,
}: {
  data: {
    images: {
      data: GalleryImage[]
    }
  }
}) {
  return (
    <Gallery images={data.images.data}>
      <div tw="gap-x-5 border-theme-accent grid md:border-none grid-template-columns[30px 1fr 30px] lg:(mx-auto gap-x-15 border-none)">
        <Gallery.Previous
          css={[
            tw`justify-self-start`,
            css`
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #9a8757;
                }
              }
            `,
          ]}
        />
        <Gallery.Next
          css={[
            tw`justify-self-end`,
            css`
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #9a8757;
                }
              }
            `,
          ]}
        />
        <Gallery.Main tw="-mx-10 border-t border-b border-theme-accent md:(border)">
          {data.images.data.map(({ id, name, attributes }: GalleryImage) => (
            <Gallery.Item
              id={id}
              name={name}
              attributes={attributes}
              key={id}
              tw="h-[305px] lg:h-[528px]"
            />
          ))}
        </Gallery.Main>
      </div>

      <div tw="mt-5 md:grid md:gap-x-5 md:grid-template-columns[30px 1fr 30px] lg:(gap-x-15 ) overflow-visible lg:overflow-hidden">
        <div tw="col-start-2 col-span-1 overflow-visible lg:overflow-hidden">
          <Gallery.Thumbnails
            css={[
              tw`col-start-2 col-span-1 flex flex-nowrap`,
              css`
                div:last-of-type {
                  margin-right: 0;
                }

                margin-right: calc((100vw - 100%) * -0.5);
                padding-right: calc((100vw - 100%) * 0.5);
              `,
            ]}>
            {data.images.data.map(
              ({ id, name, attributes }: GalleryImage, index: number) => (
                <Gallery.Thumbnail
                  index={index}
                  key={id}
                  name={name}
                  attributes={attributes}
                  css={[
                    tw`h-[50px] flex-shrink-0 mr-5 relative border border-theme-accent hover:border-[3px]`,
                    css`
                      width: calc(calc(100% / 4));

                      @media (min-width: ${theme`screens.lg`}) {
                        width: calc(calc(100% / 10) - 18px);
                      }
                    `,
                  ]}
                />
              ),
            )}
          </Gallery.Thumbnails>
        </div>
      </div>
    </Gallery>
  )
}

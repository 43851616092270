import { format, isSameDay, isSameMonth, parseISO } from 'date-fns'
import tw from 'twin.macro'

const styles = {
  day: tw`text-sm leading-tight uppercase tracking-wider`,
  month: tw`text-lg leading-tight uppercase tracking-wider`,
  full: tw`text-xs leading-tight uppercase tracking-wider`,
}

function EventDate({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) {
  const startDateISO = parseISO(startDate)
  const endDateISO = parseISO(endDate)

  if (!startDateISO || !endDateISO) {
    return null
  }

  if (isSameDay(startDateISO, endDateISO)) {
    return (
      <>
        <span css={styles.day}>{format(startDateISO, 'dd')}</span>
        <span css={styles.month}>{format(startDateISO, 'MMM')}</span>
      </>
    )
  }

  if (isSameMonth(startDateISO, endDateISO)) {
    return (
      <>
        <span css={styles.day}>
          {format(startDateISO, 'dd')}&ndash;{format(endDateISO, 'dd')}
        </span>
        <span css={styles.month}>{format(startDateISO, 'MMM')}</span>
      </>
    )
  }

  return (
    <>
      <span css={styles.full}>{format(startDateISO, 'dd MMM')}</span>
      <span css={styles.full}>&ndash;</span>
      <span css={styles.full}>{format(endDateISO, 'dd MMM')}</span>
    </>
  )
}

export { EventDate }

import tw, { css } from 'twin.macro'

import { CtaButton } from '@/types/cta-button'

import { AHref } from '@/atoms/a-href'

export function CTAButtons({ buttons, theme, website }) {
  return (
    <div
      css={[
        tw`items-end grid grid-cols-6 pb-5 xl:(grid-cols-6) gap-5`,
        css`
          grid-area: cta;
        `,
      ]}>
      {buttons.map(({ id, title, link, variant, size }: CtaButton) => (
        <AHref
          key={id}
          to={link}
          theme={theme}
          variant={variant}
          website={website}
          css={[
            size === 'One third' && tw`col-span-2`,
            size === 'Two thirds' && tw`col-span-4`,
            size === 'Half' && tw`col-span-3`,
            size === null && tw`col-span-6`,
          ]}>
          {title}
        </AHref>
      ))}
    </div>
  )
}

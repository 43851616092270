import Image from 'next/image'

import tw, { css } from 'twin.macro'

import { IEvent } from '@/types/whats-on'

import { useWebsite } from '@/hooks/use-website'
import { useCleanPathname } from '@/hooks/use-clean-path-name'

import { EventDate } from '@/atoms/event-date'
import { EventStrapline } from '@/atoms/event-strapline'
import { NextLink } from '@/atoms/next-link'

import { TermsAndConditions } from '@/molecules/ts-and-cs'

function EventCard({ event }: { event: IEvent }) {
  const website = useWebsite()

  const to =
    website === 'genting-casinos'
      ? `/whats-on/${event.attributes.slug}`
      : `/${website}/entertainment/${event.attributes.slug}`

  const path = useCleanPathname()

  return (
    <div
      className={`border ${path === '/whats-on/' ? 'border-theme-accent' : 'border-white'} bg-white text-theme-accent w-full min-h-[414px] hover:cursor-pointer`}>
      <div tw="grid grid-cols-1 grid-rows-[220px] sm:grid-rows-[280px 1fr] w-full h-full">
        <NextLink to={to}>
          <div
            css={[
              tw`relative col-span-1 col-start-1 row-start-1 row-span-1 w-full h-full bg-theme-secondary`,
              event.attributes.full_card_image && tw`row-span-2 min-h-[414px]`,
            ]}>
            {event.attributes.image && (
              <Image
                src={event.attributes.image.data.attributes.url}
                alt={event.attributes.image.data.attributes.alternativeText}
                layout="fill"
                objectFit="cover"
                objectPosition="top"
              />
            )}

            <div tw="absolute bottom-0 right-0 font-normal w-20 h-20 text-white bg-theme-accent flex flex-col justify-center items-center border-t border-l border-white">
              {event.attributes.label ? (
                <span tw="text-lg text-center px-5 leading-none">
                  {event.attributes.label}
                </span>
              ) : (
                <EventDate
                  startDate={event.attributes.dateStart}
                  endDate={event.attributes.dateEnd}
                />
              )}
            </div>
          </div>
        </NextLink>
        <div
          css={[
            tw`col-start-1 row-start-2 relative flex flex-col justify-between pl-5 pr-5 lg:pr-0`,
            event.attributes.full_card_image && tw`sr-only`,
          ]}>
          <div tw="pt-5 lg:pr-20">
            <NextLink to={to}>
              <EventStrapline {...event.attributes} />
              <h2 tw="mt-2 uppercase text-2xl leading-tighter font-normal tracking-wide text-theme-accent break-words md:hover:underline">
                {event.attributes.title}
              </h2>
            </NextLink>
            <p tw="pt-2 text-theme-inverse">{event.attributes.summary}</p>
          </div>
          {event.attributes.terms_and_conditions &&
            event.attributes.terms_and_conditions.summary && (
              <div
                tw="lg:pr-5 pt-2 pb-5 flex flex-col w-full text-black text-left"
                onClick={(event) => {
                  event.stopPropagation()
                }}>
                <TermsAndConditions
                  theme="accent"
                  {...event.attributes.terms_and_conditions}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export { EventCard }

import React from 'react'
import tw, { css, theme, styled } from 'twin.macro'
import { useRouter } from 'next/router'
import { ChevronUpIcon } from '@heroicons/react/solid'

import { ContentBlock } from '@/types/content-block'

import { AHref } from '@/atoms/a-href'
import { Container } from '@/atoms/container'
import { Slider, SliderItem } from '@/atoms/slider'

function MenuItem({
  label,
  children,
  website,
  ...rest
}: {
  label: string
  children: React.ReactNode
  website: string
}) {
  return (
    <AHref
      to={`#${label.toLowerCase().replace(/ /g, '-')}`}
      theme="accent"
      variant="text"
      outlineAccent={website === 'the-palm-beach'}
      {...rest}>
      {label}
    </AHref>
  )
}

export function CasinoNavigation({ blocks }: { blocks: ContentBlock[] }) {
  const { query } = useRouter()
  const website = query.website

  if (
    blocks.filter((block) => block.menu_label !== undefined && block.menu_label)
      .length === 0
  )
    return <></>

  return (
    <Container
      tw="fixed bottom-0 md:(sticky top-0 bottom-auto) z-40"
      variant="full">
      <div tw="grid grid-template-columns[calc(100vw - 120px) 200px] items-center bg-theme-accent border-t border-white md:(flex justify-between p-5 max-w-screen-xl mx-5 md:mx-10 xl:mx-auto border-none bg-white) shadow-xl bg-opacity-90">
        <nav>
          <ul tw="hidden md:(flex justify-evenly space-x-4 p-0)">
            {(blocks || []).map((block: ContentBlock, blockIndex: number) => {
              if (block.menu_label) {
                return (
                  <li key={blockIndex}>
                    <MenuItem
                      label={block.menu_label}
                      website={website}
                      tw="text-black">
                      {block.menu_label}
                    </MenuItem>
                  </li>
                )
              }
            })}
            {website === 'the-palm-beach' && (
              <li>
                <MenuItem label="Book" website={website} tw="text-black">
                  Book
                </MenuItem>
              </li>
            )}
          </ul>
        </nav>

        <Slider
          css={[
            tw`ml-5 pr-5 flex flex-nowrap overflow-x-auto md:hidden col-start-1 col-span-1 row-start-1`,
            css`
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              &::-webkit-scrollbar {
                display: none; /* Chrome, Safari and Opera */
              }

              & div:last-of-type {
                padding-right: 30px;
              }
            `,
          ]}>
          {(blocks || []).map((block: ContentBlock, blockIndex: number) => {
            if (block.menu_label) {
              return (
                <SliderItem
                  tw="py-5 mr-4 flex-shrink-0 text-white"
                  key={blockIndex}>
                  <MenuItem
                    label={block.menu_label}
                    website={website}
                    tw="text-white border-red-500 hover:(bg-white)">
                    {block.menu_label}
                  </MenuItem>
                </SliderItem>
              )
            }
          })}
          {website === 'the-palm-beach' && (
            <SliderItem tw="py-5 mr-4 flex-shrink-0 text-white">
              <MenuItem
                label="Book"
                website={website}
                tw="text-white border-red-500 hover:(bg-white)">
                Book
              </MenuItem>
            </SliderItem>
          )}
        </Slider>
        <span
          css={[
            website === 'the-palm-beach'
              ? tw`md:hidden py-5 z-40 col-start-1 col-span-1 row-start-1 w-1/5 justify-self-end`
              : tw`md:hidden py-5 z-40 col-start-1 col-span-1 row-start-1 w-1/5 justify-self-end bg-gradient-to-l from-[rgba(123,3,11,1)]`,
          ]}>
          &nbsp;
        </span>

        <div tw="flex col-start-2 col-span-1 row-start-1 ">
          <AHref
            to={
              website === 'genting-casinos'
                ? '/contact-us'
                : `/${website}/contact-us/`
            }
            theme="accent"
            variant="contained"
            tw="hidden md:inline-block py-3 px-6.5 text-base hover:(bg-white text-theme-accent)"
            outlineAccent={website === 'the-palm-beach'}>
            Contact
          </AHref>
          <AHref
            to={
              website === 'genting-casinos'
                ? '/contact-us'
                : `/${website}/contact-us/`
            }
            theme="default"
            variant="inverse"
            tw="md:hidden px-3 py-1 mr-3.5 text-xs hover:(bg-white text-theme-accent)"
            outlineAccent={website === 'the-palm-beach'}>
            Contact
          </AHref>

          <div tw="md:hidden w-[26px] h-[26px] bg-white">
            <ChevronUpIcon
              tw="w-full h-full text-black hover:(cursor-pointer text-theme-accent)"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

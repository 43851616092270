import tw, { css } from 'twin.macro'

import { FaqBlock as FaqBlockTypes } from '@/types/faq-block'

import { Container } from '@/atoms/container'
import { FaQuestionDisclosure } from '@/atoms/faq-disclosure'
import { Heading } from '@/atoms/headings'
import { NextLink } from '@/atoms/next-link'

export function FaqBlock({
  data,
  variant,
}: {
  data: FaqBlockTypes
  variant: 'casino' | 'page'
}) {
  return (
    <Container
      variant="full"
      css={[
        tw`py-16 relative text-white`,
        variant === 'page' && tw`bg-theme-secondary`,
        variant === 'casino' && tw`bg-theme-accent`,
      ]}>
      <Container tw="pb-10">
        <div className="flex flex-col items-center ">
          <div className=" flex flex-col items-center mb-5 text-center">
            <div className="text-theme-primary mt-5 mb-">{data.strapline}</div>
            <Heading variant="h2" tw="mt-5 mb-3">
              {data.heading}
            </Heading>
            <hr className="border-b border-gray-100 my-5 border-opacity-50 w-2/5" />
          </div>

          <FaQuestionDisclosure data={data.faq_questions.data} />
          <NextLink
            to="/faqs"
            css={[
              tw`bg-white w-full md:w-auto mt-12 py-2 px-8 text-center border`,
              variant === 'casino' && tw`text-theme-accent`,
              variant === 'page' && tw`text-theme-secondary`,
            ]}>
            All FAQs
          </NextLink>
        </div>
      </Container>
    </Container>
  )
}

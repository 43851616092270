import { useEffect, useState } from 'react'

import { JackpotGames, Jackpot } from '@/types/atlas-jackpots'

export const useStoredJackpots = (slug?: string, id?: number) => {
  const [jackpotGames, setJackpotGames] = useState<JackpotGames>({})
  const [nationalJackpots, setNationalJackpots] = useState<Jackpot[]>([])
  const [lastUpdated, setLastUpdated] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)

  useEffect(() => {
    if (Object.keys(jackpotGames)) {
      setNationalJackpots(getNationalValues())
    }
  }, [jackpotGames])

  useEffect(() => {
    const cachedJackpotGames = localStorage.getItem('jackpotData')

    const getNationalJackpots = async () => {
      try {
        if (cachedJackpotGames) localStorage.removeItem('jackpotData')

        const res = await fetch(`/api/national-jackpots/`)

        if (res.status !== 200) {
          setJackpotGames({})
          setLastUpdated('')

          return
        }

        const json = await res.json()

        return json
      } catch (error) {
        console.error(error)
      }
    }

    const getLocalJackpots = async () => {
      try {
        const res = await fetch(`/api/local-jackpots/?siteID=${id}`)

        if (res.status !== 200) return

        const json = await res.json()

        return json.data
      } catch (error) {
        console.error(error)
      }
    }

    const restructureJackpots = (games) => {
      const result: any = {}

      games.forEach((game) => {
        const gameKey = game.name
        if (!result[gameKey]) {
          result[gameKey] = {}
        }

        game.jackpots.forEach((jackpot) => {
          const jackpotKey =
            jackpot.type === 'local' && slug ? slug : jackpot.type

          result[gameKey] = {
            [jackpotKey]: Object.keys(jackpot).includes('success')
              ? {
                  success: jackpot.success,
                }
              : {
                  name: jackpot.name,
                  value: jackpot.value,
                  type: jackpot.type,
                  id: jackpot.type === 'national' ? -1 : id,
                  success: true,
                },
            ...result[gameKey],
          }
        })
      })

      return result
    }

    const addLocalJackpot = async (cachedJackpots) => {
      const local = await getLocalJackpots()

      const jackpots = { ...cachedJackpots.games }

      if (!local) {
        for (const [key, game] of Object.entries(jackpots)) {
          jackpots[key][slug] = { success: false }
        }

        setJackpotGames(jackpots)
        return
      }

      local.games.map((game) => {
        jackpots[game.name][slug] = { ...game.jackpots[0], success: true }
      })

      setJackpotGames(jackpots)
      localStorage.setItem(
        'jackpotData',
        JSON.stringify({ ...cachedJackpots, games: jackpots }),
      )
    }

    const getJackpotGames = async () => {
      const national = await getNationalJackpots()

      if (!id) {
        if (!national) {
          setFailed(true)

          return
        }

        if (failed) setFailed(false)

        const nationalRestructure = restructureJackpots(national.games)
        setJackpotGames(nationalRestructure)
        setLastUpdated(national.last_updated)
        localStorage.setItem(
          'jackpotData',
          JSON.stringify({
            last_updated: national.last_updated,
            games: nationalRestructure,
          }),
        )

        return
      }

      const local = await getLocalJackpots()

      if (national && !local) {
        const games = national.games.map((game) => ({
          ...game,
          jackpots: [...game.jackpots, { success: false, type: 'local' }],
        }))
        const nationalRestructure = restructureJackpots(games)
        setJackpotGames(nationalRestructure)
        setLastUpdated(national.last_updated)
        localStorage.setItem(
          'jackpotData',
          JSON.stringify({
            games: nationalRestructure,
            last_updated: national.last_updated,
          }),
        )

        return
      } else if (!national && local) {
        const games = local.games.map((game) => ({
          ...game,
          jackpots: [...game.jackpots, { success: false, type: 'national' }],
        }))
        const localRestructure = restructureJackpots(games)
        setJackpotGames(localRestructure)
        setLastUpdated(local.last_updated)
        localStorage.setItem(
          'jackpotData',
          JSON.stringify({
            games: localRestructure,
            last_updated: local.last_updated,
          }),
        )

        return
      } else if (!national && !local) {
        setFailed(true)

        return
      }

      setFailed(false)

      const jackpotsCollection = restructureJackpots([
        ...local.games,
        ...national.games,
      ])

      setJackpotGames(jackpotsCollection)
      setLastUpdated(local.last_updated)
      localStorage.setItem(
        'jackpotData',
        JSON.stringify({
          games: jackpotsCollection,
          last_updated: local.last_updated,
        }),
      )
    }

    if (cachedJackpotGames !== null) {
      const storedJackpotData = JSON.parse(cachedJackpotGames)

      // To be removed
      const expectedKeys = ["last_updated", "games"]

      for (const key of expectedKeys) {
        if (!(key in storedJackpotData)) {
          getJackpotGames()

          return
        }
      }

      const current = Math.floor(Date.now() / 1000)
      const changedTimestamp = Math.floor(
        new Date(storedJackpotData.last_updated).getTime() / 1000,
      )

      const difference = current - changedTimestamp

      const nationalArray = getNationalValues(storedJackpotData.games)

      if (difference >= 15 * 60 || nationalArray.length === 0) {
        getJackpotGames()

        return
      }

      let localJackpotsStored = false

      for (const [key, game] of Object.entries(storedJackpotData.games)) {
        if (game[slug] && game[slug].success) {
          localJackpotsStored = true
          break
        } else if (game[slug] && !game[slug].success) {
          delete game[slug]

          break
        }
      }

      if (!id || (id && localJackpotsStored)) {
        setJackpotGames(storedJackpotData.games)
      } else if (id && !localJackpotsStored) {
        setJackpotGames(storedJackpotData.games)
        addLocalJackpot(storedJackpotData)
      }

      setLastUpdated(storedJackpotData.last_updated)

      return
    }

    getJackpotGames()
  }, [slug])

  const getNationalValues = (games?) => {
    const nationalJackpots = []
    const gamesArray = games ?? jackpotGames

    for (const [key, game] of Object.entries(gamesArray)) {
      for (const [jackpotKey, jackpot] of Object.entries(game)) {
        if (jackpot.type === 'national' && jackpot.success)
          nationalJackpots.push(jackpot)
      }
    }

    return nationalJackpots
  }

  const hasLocalValuesBeenFetched = (slug) => {
    for (const [gameKey, game] of Object.entries(jackpotGames)) {
      for (const [key, jackpot] of Object.entries(game)) {
        if (key === slug) return true
      }
    }

    return false
  }

  const getGameByName = (game, slug) => {
    if (failed) {
      return {
        national: {
          success: false,
        },
        [slug]: {
          success: false
        }
      }
    }

    const hasJackpots = hasLocalValuesBeenFetched(slug)
    
    for (const [key, value] of Object.entries(jackpotGames)) {
      if (game.includes(key.toLowerCase())) {
        let jackpotMissing = true
        for (const [jackpotKey, jackpot] of Object.entries(value)) {
          if (slug === jackpotKey) {
            jackpotMissing = false
          }
        }
        if (hasJackpots && jackpotMissing) {
          value[slug] = { success: false }
        }

        return value
      }
    }
  }

  const getJackpotByName = (game, name, games?) => {
    if (failed) {
      return {
        national: {
          success: false,
        },
      }
    }

    const gamesArray = games ?? jackpotGames

    if (gamesArray[game] && gamesArray[game][name])
      return gamesArray[game][name]

    for (const [key, value] of Object.entries(gamesArray)) {
      if (game.includes(key.toLowerCase())) {
        if (!name) return value

        for (const [jackpotKey, jackpot] of Object.entries(value)) {
          if (name === jackpotKey) return jackpot
        }
      }
    }
  }

  return {
    nationalJackpots,
    getJackpotByName,
    getGameByName,
    lastUpdated
  }
}

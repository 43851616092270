import { ReactElement } from 'react'
import { ContentTwoColumns as ContentTwoColumnsTypes } from '@/types/content-two-columns'

import { RenderMarkdown } from '@/lib/render-markdown'

import { Grid } from '@/atoms/grid'
import { Typography } from '@/atoms/typography'
import { Container } from '@/atoms/container'

const CustomParagraph = ({ children, ...props }: { children: ReactElement }) => (
    <p {...props} tw='!mt-1 mb-6'>
        {children}
    </p>
)
const CustomOrderedList = ({ children, ...props }: { children: ReactElement }) => (
    <ol {...props} tw='!mt-1 mb-6'>
        {children}
    </ol>
)
const CustomUnOrderedList = ({ children, ...props }: { children: ReactElement }) => (
    <ul {...props} tw='!mt-1 mb-6'>
        {children}
    </ul>
)

const options = {
    overrides: {
        p: {
            component: CustomParagraph,
        },
        ol: {
            component: CustomOrderedList,
        },
        ul: {
            component: CustomUnOrderedList,
        },
    },
}

function ContentTwoColumn({ data }: { data: ContentTwoColumnsTypes }) {
    return (
        <Container tw='pb-20'>
            <div tw='mt-6 md:mt-12'>
                <Grid gap={20} cols={2}>
                    <Grid.Item>
                        <Typography>
                            {/* @ts-ignore */}
                            <RenderMarkdown options={options}>{data.left_copy}</RenderMarkdown>
                        </Typography>
                    </Grid.Item>
                    <Grid.Item>
                        <Typography>
                            {/* @ts-ignore */}
                            <RenderMarkdown options={options}>{data.right_copy}</RenderMarkdown>
                        </Typography>
                    </Grid.Item>
                </Grid>
            </div>
        </Container>
    )
}

export { ContentTwoColumn }

import { Icon } from '@/atoms/icon'
import { NextLink } from '@/atoms/next-link'
import { Disclosure } from '@headlessui/react'
import tw, { css } from 'twin.macro'

export type DisclosureType = {
	clip: string
	summary: string
	link?: {
		label: string
		url: string
	}
	theme?: 'default' | 'accent'
}

const TermsAndConditions = ({ theme = 'default', summary, link }: DisclosureType) => {
	return (
		<>
			<Disclosure>
				{({ open }) => (
					<Disclosure.Button
						css={[
							css`text-align: inherit`,
							tw`items-center flex font-normal justify-between text-xs w-full`,
							open && tw`items-end`
						]}>
						{!open && (
							<span tw="truncate h-[20px]">
								{summary}
								<br />
							</span>
						)}
						{open && (<Disclosure.Panel>{summary}</Disclosure.Panel>)}
						<Icon
							icon={open ? 'upArrow' : 'downArrow'}
							variant='solid'
							tw='w-2.5 h-2.5 w-5 ml-1 flex-shrink-0'
							css={[theme && theme == 'accent' && tw`text-theme-accent`]}
						/>
					</Disclosure.Button>
				)}
			</Disclosure>

			{link && link.url && (
				<NextLink to={link?.url} css={[
					theme && theme == 'accent' && tw`text-theme-accent`,
					tw`underline text-xs cursor-pointer font-normal`,
				]}>
						{link?.label}
				</NextLink>
			)}
		</>
	)
}

export { TermsAndConditions }
